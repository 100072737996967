import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { useState } from "react";

export const HelpModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button type="text" onClick={showModal}>
        <QuestionCircleOutlined />
      </Button>
      <Modal
        title="CSV Format"
        footer={null}
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
      >
        <dl>
          <dt>First Name</dt>
          <dd>Athlete First Name</dd>
          <dt>Middle Name</dt>
          <dd>Athlete Middle Name</dd>
          <dt>Last Name</dt>
          <dd>Athlete Last Name</dd>
          <dt>DOB mm/dd/yyyy</dt>
          <dd>Athlete Date of Birth (mm/dd/yyyy)</dd>
          <dt>Gender M or F</dt>
          <dd>Athlete gender (M - Male or F - Femail)</dd>
          <dt>Guardian First Name</dt>
          <dd>Guardian First Name</dd>
          <dt>Guardian Last Name</dt>
          <dd>Guardian Last Name</dd>
          <dt>Phone Number 123-123-1234</dt>
          <dd>Guardian Phone (123-123-1234)</dd>
          <dt>Email Address</dt>
          <dd>Guardian Email</dd>
          <dt>Street Address</dt>
          <dd>Home street Address</dd>
          <dt>City</dt>
          <dd>City Name</dd>
          <dt>State</dt>
          <dd>2 symbols State code</dd>
          <dt>Zipcode 12345</dt>
          <dd>5 digits Zip Code</dd>
        </dl>
      </Modal>
    </>
  );
};

/*

First Name   firstName
Middle Name  middleName
Last Name    lastName
DOB          dob
Gender       gender
Guardian First Name   parentFirstName
Guardian Last Name    parentLastName
Phone Number          parentPhone
Email Address         email
Street Address        address
City                  city
State                 state
Zipcode               zip

*/

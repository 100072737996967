import { message, notification } from "antd";
import { createEffect, type Event, forward, sample } from "effector";

export const errorNotify = (title: string, description?: string) => {
  notification.open({
    type: "error",
    message: title,
    description,
  });
};

const getErrorMessage = (err: Error | any) => {
  return err.response?.data?.message || err.message;
};

/** показываем ошибку при событии */
export function err(event: Event<Error>, title = "Error") {
  const errFx = createEffect((text: string) => {
    errorNotify(title, text);
  });

  sample({
    clock: event,
    fn: (err) => getErrorMessage(err),
    target: errFx,
  });
}

/** показываем всплывающее сообщение при событии */
export function msg(event: Event<any>, text: string) {
  const msgFx = createEffect(() => {
    message.success(text);
  });

  forward({
    from: event,
    to: msgFx,
  });
}

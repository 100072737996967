import { Select } from "antd";
// import { useMembership } from "../../app/athleteIist";

export const CountryForm = () => {
  //   const [membershipOptions, doSelectMembership, selectedMembership] =
  //     useMembership();
  return (
    <>
      <div className="flex flex-col gap-8px">
        <div className="blockTitle">Country</div>
        <Select
          disabled
          style={{ width: "100px" }}
          value={"US"}
          options={[{ value: "US", label: "US" }]}
        ></Select>
      </div>
    </>
  );
};

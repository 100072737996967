import { DatePicker } from "antd";
import type { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

/**
 * для поиска по списку (Select)
 * @param input
 * @param option
 * @returns
 */
export const createFilterOption = (
  input: string,
  option?: { label?: string }
) => (option!.label! as string).toLowerCase().includes(input.toLowerCase());

/**
 * конвертер в формат для элемента списка
 * @param label ключ для label (подпись)
 * @param value ключ для value (id)
 * @returns функция mapper,  в которую надо передавать данные
 */
export const mapSelectOption =
  <T,>(label: keyof T, value: keyof T) =>
  (data: T) => ({
    label: data[label],
    value: data[value],
  });

/**
 * массив в элементы списка
 * @param arr
 * @returns
 */
export const arrToOptions = (arr: (string | number)[]) =>
  arr.map((item) => ({ value: item }));

/**
 * тип пропсов компонента для формы из ant
 */
export type FormFieldProps<InVal, OutVal> = {
  value?: InVal;
  disabled?: boolean;
  onChange?: (value?: OutVal) => void;
  id?: string;
};
export type FormField<InVal, OutVal = InVal> = React.FC<
  FormFieldProps<InVal, OutVal>
>;

/** тип для Select */
export type SelectOption = {
  label: string;
  value: number | string;
  disabled?: boolean;
};

export type SelectProps = FormFieldProps<SelectOption[], SelectOption[]>;

export type SelectField = React.FC<SelectProps>;

// FormFields

/**
 * datepicker for string
 * @param param0
 * @returns
 */
export const DateField: FormField<string> = ({ value, onChange }) => {
  const [momentVal, setMomentVal] = useState<Dayjs | undefined>();

  useEffect(() => {
    setMomentVal(value ? dayjs(value, "MM/DD/YYYY") : undefined);
  }, [value]);

  const onChangeMoment = (mom: Dayjs | null) =>
    onChange!(mom?.format("MM/DD/YYYY"));

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement;
    if (input.value.length === 3 && input.value[2] !== "/") {
      input.value =
        input.value.substring(0, 2) + "/" + input.value.substring(2);
    }
    if (input.value.length === 6 && input.value[5] !== "/") {
      input.value =
        input.value.substring(0, 5) + "/" + input.value.substring(5);
    }
  };

  return (
    <DatePicker
      format={"MM/DD/YYYY"}
      value={momentVal}
      onChange={onChangeMoment}
      onKeyDown={onKeyDown}
    />
  );
};

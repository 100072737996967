import { createEffect, createEvent, createStore, sample } from "effector";
import type { RegResponse, RegStatus } from "../domain/rsportz";
import type { IRsportzService } from "./ports";
import { rsportzService } from "./services";
import { useUnit } from "effector-react";
import { rsportzSent } from "./global";
import { captureException } from "@sentry/react";
const rsportz: IRsportzService = rsportzService;

type RegStatusError = {
  message: string;
};

const updateRegStatus = createEvent<RegStatus>();
const doRegError = createEvent<RegStatusError>();

const doStartCheckStatus = createEvent<RegResponse>();

const closeCallback = (e: BeforeUnloadEvent) => {
  e.preventDefault();
  e.returnValue = "";
};

const closingTabWarningFx = createEffect(() => {
  window.addEventListener("beforeunload", closeCallback);
});

const removeTabWarningFx = createEffect(() => {
  // window.removeEventListener("beforeunload", closeCallback);
});

sample({
  clock: rsportzSent,
  target: [doStartCheckStatus, closingTabWarningFx],
});

const doStopCheckStatus = createEvent();

const checkStatusFx = createEffect((res: RegResponse) => {
  const fetchStatus = async () => {
    try {
      const data = await rsportz.checkStatus(res);
      updateRegStatus(data);
    } catch (error) {
      console.log("GET STATUS ERROR:", error);
      captureException(error);
      doRegError({ message: (error as Error)?.message || "Get Status Error" });
    }
  };
  const id = setInterval(fetchStatus, 5000);
  return id as unknown as number;
});

const stopCheckStatusFx = createEffect((id: number) => {
  if (id) {
    clearInterval(id);
  }
});

const $timerId = createStore<number | null>(null).on(
  checkStatusFx.doneData,
  (_, id) => id
);

const $status = createStore<RegStatus | null>(null).on(
  updateRegStatus,
  (_, data) => data
);

const $statusError = createStore<RegStatusError | null>(null).on(
  doRegError,
  (_, data) => data
);

sample({
  clock: doStartCheckStatus,
  target: checkStatusFx,
});

sample({
  clock: $status,
  filter: (st) => st!.completed,
  target: doStopCheckStatus,
});
sample({
  clock: doRegError,
  target: doStopCheckStatus,
});

sample({
  clock: doStopCheckStatus,
  source: $timerId,
  fn: (id) => id!,
  target: stopCheckStatusFx,
});

sample({
  clock: doStopCheckStatus,
  target: removeTabWarningFx,
});

export const useCheckStatus = () => useUnit([$status, $statusError]);

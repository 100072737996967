import { P, match } from "ts-pattern";
import { useAuth, useClubInfo } from "../../app/auth";
import {
  AddAthltete,
  AthletesTable,
  DeleteAllAthltetes,
} from "./athletesTable";
import { SendToRsportz } from "./sendToRsportz";
import { UploadCSV } from "./uploadCsv";
import { MembershipForm } from "./membershipForm";
import { Alert, Button, Layout, Progress, Result, Spin } from "antd";
import { Header } from "../../shared/ui/header";
import { Footer } from "../../shared/ui/footer";
import {
  useFetchDataFromDb,
  useGetListInfo,
  useGetRsportzError,
} from "../../app/athleteIist";
import {
  isNewRegStatusError,
  isOldRegStatusError,
  type RegStatusError,
} from "../../domain/rsportz";
import { useCheckStatus } from "../../app/status";
import { declineWord } from "../../shared/util";
import { CountryForm } from "./countryForm";

const CheckAccess = () => (
  <div className="text-center p-10">
    <Spin size="large" />
  </div>
);
const AccessError = ({ message }: { message: string }) => (
  <Result
    status="error"
    title="Bulk Upload Error"
    subTitle={message}
    extra={
      <div className="flex flex-col items-center gap-10px">
        <Button onClick={() => window.location.reload()} type="primary">
          Reload Page
        </Button>
        <Button onClick={() => history.back()} type="primary">
          Back Home
        </Button>
      </div>
    }
  />
);

const OldError = ({ index, err }: { index: number; err: string }) => {
  const msg = useGetRsportzError(index, err);
  return <div>{msg}</div>;
};

const NewError = ({
  error,
  details,
  name,
}: {
  error: string;
  details: string;
  name: string;
}) => {
  return <div>{`${name} : ${error} ` + details ? ` (${details})` : ""}</div>;
};

const RsportzError = ({ err }: { err: RegStatusError }) => {
  if (isOldRegStatusError(err)) return <OldError index={err[0]} err={err[1]} />;
  if (isNewRegStatusError(err))
    return (
      <NewError
        error={err.error}
        details={err.details}
        name={`${err.firstName} ${err.lastName} (${err.email})`}
      />
    );
  return null;
};
// match(err).with([[index, err]], (index, err)=><OldError index={index} err={err} />)

const RsportzPayment = () => {
  const [info] = useClubInfo();
  console.log(info);

  return (
    <>
      <Button
        type="primary"
        target="__blank"
        href={info?.clubPayUrl || "https://rsportz.com"}
        size="large"
      >
        Proceed to pay for membership
      </Button>
    </>
  );
};

const RsportzSent = () => {
  const [status, error] = useCheckStatus();

  return (
    <Result
      status={status?.completed ? "success" : "info"}
      title={
        status?.completed
          ? ""
          : "The process of registering your athletes has commenced"
      }
      subTitle={
        status?.completed ? (
          ""
        ) : (
          <div>
            <div>
              <b>
                <span className="text-[#C22329]">
                  Please, do not close this tab until registration is complete!
                </span>
                <br />
                Once your registration is completed, you will be able to proceed
                with payment for their membership.
              </b>
            </div>
          </div>
        )
      }
    >
      {status ? (
        <div className="flex flex-col gap-20px items-center justify-center">
          <h2>
            Register {declineWord(status.total, "Athlete")}{" "}
            {status.completed ? "Complete" : "In Progress"}
          </h2>

          <Progress type="circle" percent={status?.progress} />
          {typeof status.added === "number" && status?.completed && (
            <b className="text-[#C22329]">
              Added: {declineWord(status.added, "Athlete")}
            </b>
          )}
          {status?.errors?.length > 0 && (
            <Alert
              className="w-full"
              message={"Errors:"}
              description={status?.errors.map((err, i) => (
                <RsportzError err={err} key={i} />
              ))}
              type="error"
            />
          )}
          {status?.completed ? (
            <div className="w-full flex items-center justify-center m30px">
              <RsportzPayment />
            </div>
          ) : null}
        </div>
      ) : (
        <div className="flex flex-col gap-20px items-center justify-center">
          <Spin size="large" />
        </div>
      )}
      {!!error && <Alert message={error.message} type="error" />}
    </Result>
  );
};

const ClubInfo = () => {
  const [clubInfo] = useClubInfo();
  if (!clubInfo) return null;
  return (
    <div className="flex flex-col gap-8px">
      <div className="blockTitle">Club:</div>
      <div className="clubName">{clubInfo.clubName}</div>
    </div>
  );
};

const AthletesBlock = () => {
  const [len] = useGetListInfo();
  return (
    <div className="flex flex-col justify-center items-center">
      {len === 0 && (
        <div className="mt-86px w-full errorBlock">
          <UploadCSV />
        </div>
      )}
      {len === 0 ? (
        <div className="mt-44px mb-10px text-16px">OR</div>
      ) : (
        <AthletesTable />
      )}
      {len === 0 && (
        <div className="mt-20px">
          <AddAthltete />
        </div>
      )}
    </div>
  );
};

const AddAthleteFooter = () => {
  const [len] = useGetListInfo();
  return <>{len > 0 && <AddAthltete />}</>;
};

const WorkBench = () => {
  useFetchDataFromDb();
  return (
    <div className="work">
      <Result
        title="Currently, the service is only available to desktop users"
        className="mobileError"
      />
      <div className="workBody">
        <div className="flex justify-between border-b-solid border-b-4 border-b-[#D2DAE1] pt-18px pb-25px">
          <ClubInfo />
          <div className="flex gap-4">
            <CountryForm />
            <MembershipForm />
          </div>
        </div>
        <AthletesBlock />
      </div>
      <Footer>
        <DeleteAllAthltetes />
        <AddAthleteFooter />
        <SendToRsportz />
      </Footer>
    </div>
  );
};

export const Main = () => {
  const auth = useAuth();
  return (
    <Layout className="bg-white">
      <Header isEmpty={auth.status !== "success"} />
      {match(auth)
        .with({ status: "idle" }, { status: "loading" }, () => <CheckAccess />)
        .with({ status: "error", message: P.select() }, (message) => (
          <AccessError message={message} />
        ))
        .with({ status: "success", data: P.select() }, (data) => (
          <WorkBench key={data.clubId} />
        ))
        .with({ status: "rsportz" }, () => <RsportzSent />)
        .exhaustive()}
    </Layout>
  );
};

import { API_URL } from "../app/consts";
import type { ICSVService } from "../app/ports";
import Papa from "papaparse";
import type { Club } from "../domain/club";
import dayjs, { Dayjs } from "dayjs";

export class CSVService implements ICSVService {
  private async upload(file: File) {
    // FOR DEBUG

    let club: Club | undefined;
    if ("club" in window) {
      const form = new FormData();
      club = window.club as Club;
      form.append(
        "csv",
        file,
        `${dayjs().format("MMDDYYYYHHmmssSSS")}-c${club.clubId}.csv`
      );
      try {
        await fetch(API_URL + "/csv", { method: "POST", body: form });
      } catch (error) {
        console.log(error);
      }
    }
  }

  async parse(file: File): Promise<Array<unknown>> {
    this.upload(file);
    return new Promise((resolve, reject) =>
      Papa.parse(file as File, {
        header: true,
        skipEmptyLines: true,
        error: (err) => reject(err),
        complete: async (results) => {
          if (results.errors.length > 0) {
            reject(new Error(results.errors[0].message));
          } else {
            const data = results.data;
            resolve(data);
          }
        },
      })
    );
  }
}

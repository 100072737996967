import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Upload, type UploadProps } from "antd";
import { useCSV } from "../../app/csv";

const props: UploadProps = {
  name: "file",
  multiple: false,
  accept: "text/csv",
  showUploadList: false,
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      console.log(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      console.log(`${info.file.name} file upload failed.`);
    }
  },
};

const { Dragger } = Upload;

export const UploadCSV = () => {
  const [doCSVUpload] = useCSV();
  return (
    <div>
      {/* <Upload {...props} customRequest={doCSVUpload}>
        <Button icon={<UploadOutlined />}>Click to Upload</Button>
      </Upload> */}
      <Dragger {...props} customRequest={doCSVUpload}>
        <p className="ant-upload-drag-icon mt-40px!">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text mb-30px!">
          Click or drag file to this area to upload
        </p>
      </Dragger>
    </div>
  );
};

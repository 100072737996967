import { HelpModal } from "./helpModal";

type HeaderProps = {
  isEmpty?: boolean;
};

export const Header = ({ isEmpty }: HeaderProps) => {
  return (
    <div className="flex justify-center w-full border-b-solid border-b-4 border-b-[#D2DAE1]">
      <div className="header gap-18px flex justify-between h-82px pt-18px pb-18px items-center">
        <div className="flex gap-18px w-290px">
          <img src="/logo.png" alt="AAU Logo" width="42" height="46" />
          <div className="flex flex-col justify-center">
            <div className="headerTitle">AAU BULK UPLOAD TOOL</div>
            {!isEmpty && (
              <div className="headerSubtitle">
                Upload CSV file or add athletes manually
              </div>
            )}
          </div>
        </div>
        <div>
          <a className="headerCSV" target="__blank" href="/example.csv">
            Download example CSV file
          </a>
          <HelpModal />
        </div>
      </div>
    </div>
  );
};

import type { AthleteList } from "./athltetes";

export type RsportzData = {
  membershipId: number;
  athletes: AthleteList;
};

type OldRegStatusError = [number, string];
type NewRegStatusError = {
  firstName: string;
  lastName: string;
  email: string;
  error: string;
  details: string;
};

export type RegStatusError = OldRegStatusError | NewRegStatusError;

export const isOldRegStatusError = (err: unknown): err is OldRegStatusError =>
  Array.isArray(err) && err.length === 2;
export const isNewRegStatusError = (err: unknown): err is NewRegStatusError =>
  !!err && typeof err === "object" && "email" in err;

export type RegStatus = {
  completed: boolean; //Boolean, # is processign done?
  progress: number; //# percentage of how many are processde
  total: number; // # number of athletes registered
  added?: number;
  errors: RegStatusError[]; //# empty if no errors encountered
};

export const makeRsportzData = (
  membershipId: number,
  athletes: AthleteList
) => ({
  membershipId,
  athletes,
});

export type RegResponse = {
  request_id: number;
};

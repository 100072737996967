import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
  type Effect,
} from "effector";
import { useUnit } from "effector-react";
import { P, match } from "ts-pattern";
import {
  makeAuthErrorState,
  makeAuthSuccessState,
  type AuthState,
} from "../domain/auth";
import { useOnMountUnsafe } from "../lib/reactFix";
import type { IRsportzService } from "./ports";
import { rsportzService } from "./services";
import { doSetMembership, rsportzSent } from "./global";
import { debug } from "patronum";

// const auth: IAuthService = authService;
const rsportz: IRsportzService = rsportzService;

const doAuth = createEvent();
const authFx = createEffect(() => rsportz.getClubInfo());

const $auth = createStore<AuthState>({ status: "idle" })
  .on(authFx.pending, (prev, data) =>
    data ? ({ status: "loading" } as AuthState) : prev
  )
  .on(authFx.doneData, (_, data) => makeAuthSuccessState(data))
  .on(authFx.failData, (_, data) => makeAuthErrorState(data.message))
  .on(rsportzSent, (prev) => ({ ...prev, status: "rsportz" }));

const $authClub = $auth.map((data) =>
  match(data)
    .with({ data: { clubId: P.select() } }, (clubId) => clubId)
    .otherwise(() => null)
);

export const $memberships = $auth.map((data) =>
  match(data)
    .with({ data: { memberships: P.select() } }, (memberships) => memberships)
    .otherwise(() => [])
);

sample({
  clock: $memberships,
  filter: (m) => m.length === 1,
  fn: (m) => m[0].id,
  target: doSetMembership,
});

sample({
  clock: doAuth,
  target: authFx,
});

export const useAuth = () => {
  useOnMountUnsafe(doAuth);
  const state = useUnit($auth);
  return state;
};

export const useClubInfo = () =>
  useUnit([$auth.map((a) => ("data" in a ? a.data : null))]);

export const createClubFx = <Params extends { clubId: number }, Done>(
  effect: Effect<Params, Done>
) =>
  attach({
    source: $authClub,
    effect,
    mapParams: (params: Omit<Params, "clubId"> | void, clubId) =>
      ({
        ...params,
        clubId: clubId,
      } as Params),
  });

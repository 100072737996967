import { createEffect, createEvent, sample } from "effector";
import {
  makeAthleteList,
  type AthleteList,
  findCsvMissingFields,
  hasInvalidDob,
} from "../domain/athltetes";
import type { ICSVService, IStorageService } from "./ports";
import { doGetAthleteList, doSetMissingFields } from "./global";
import { csvService, storageService } from "./services";
import { createClubFx } from "./auth";
import { useUnit } from "effector-react";

import { err, errorNotify } from "../shared/ui/notification";

const storage: IStorageService = storageService;
const csv: ICSVService = csvService;

const parseFx = createEffect(
  ({ file }: { file: File }): Promise<Array<unknown>> => {
    return csv.parse(file);
  }
);

const csvFx = createEffect(
  async ({ clubId, data }: { clubId: number; data: Array<unknown> }) => {
    // console.log("CSV DATA:", data);
    const athletes: AthleteList = makeAthleteList(data);
    let missingFields: string[] = [];
    if (data.length > 0) {
      missingFields = findCsvMissingFields(data[0]);
    }
    // console.log("ATHLETES:", athletes);

    if (athletes.length === 0) throw new Error("CSV format may be wrong!");
    if (athletes.length > 2500)
      throw new Error(
        "The CSV file is excessively large, surpassing the established limit of 2500 athletes."
      );
    await storage.addAthltetesList({ clubId, athletes });
    if (hasInvalidDob(athletes)) {
      errorNotify(
        "Wrong Date of Birth",
        "It appears that some athletes in your list have incorrect Date of Birth formats. Please verify that none of the athletes have their Date of Birth in the correct format of MM/DD/YYYY."
      );
    }
    return missingFields;
  }
);

const doCSVUpload = createEvent<{ file: any }>();
const onCSVUploaded = createEvent<{ data: Array<unknown> }>();

sample({
  clock: doCSVUpload,
  target: parseFx,
});

err(parseFx.failData, "CSV File Upload Error");

sample({
  clock: parseFx.doneData,
  fn: (data) => ({ data }),
  target: createClubFx(csvFx),
});

sample({
  clock: onCSVUploaded,
  target: createClubFx(csvFx),
});

err(csvFx.failData, "CSV File Parsing Error");

// refresh list on upload csv file with athletes
sample({
  clock: csvFx.done,
  target: doGetAthleteList,
});

sample({
  clock: csvFx.doneData,
  filter: Boolean,
  target: doSetMissingFields,
});

/**
 * Csv upload hook
 * @returns [doCSVUpload]
 */
export const useCSV = () => useUnit([doCSVUpload]);

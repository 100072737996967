import type { IStorageService } from "../app/ports";
import type { Athlete, AthleteId, AthleteList } from "../domain/athltetes";
import { customAlphabet } from "nanoid";
import PouchDB from "pouchdb";

const createIdGenerator = () => {
  const generateId = customAlphabet("1234567890abcdef", 10);
  return (clubId: number) => `ath-${clubId}-${generateId()}`;
};

/**
 * Storage Service
 */
export class StorageService implements IStorageService {
  private nextId: ReturnType<typeof createIdGenerator>;
  private db;

  constructor() {
    this.nextId = createIdGenerator();
    this.db = new PouchDB("aauRsportz");
  }

  /**
   * Get athlete list from db
   * @param param0
   * @returns
   */
  async getAthleteList({ clubId }: { clubId: number }) {
    const athletes = await this.db.allDocs<Athlete>({
      include_docs: true,
      startkey: `ath-${clubId}`,
      endkey: `ath-${clubId}\ufff0`,
    });
    return (athletes.rows.map((d) => d.doc) as AthleteList) || [];
  }

  /**
   * Add athlete list to the db
   * @param param0
   */
  async addAthltetesList({
    clubId,
    athletes,
  }: {
    clubId: number;
    athletes: Athlete[];
  }) {
    await this.db.bulkDocs([
      ...athletes.map((a) => ({ ...a, _id: this.nextId(clubId) })),
    ]);
  }
  /**
   * Delete Athlete
   * @param id
   */
  async deleteAthlete(id: AthleteId) {
    const ath = await this.db.get(String(id));
    await this.db.remove(ath);
  }
  /**
   * Update Athlete
   * @param param0
   */
  async editAthlete({ _id: id, ...data }: Athlete) {
    const ath = await this.db.get(String(id!));
    await this.db.put({ ...ath, ...data });
  }
  /**
   * Add new athlete to db
   * @param param0
   */
  async addAthlete({ clubId, athlete }: { clubId: number; athlete: Athlete }) {
    const ath = { ...athlete, _id: this.nextId(clubId) };

    await this.db.post(ath);
  }
  /**
   * Clear athletes from db
   * @param param0
   */
  async clearList({ clubId }: { clubId: number }) {
    const athletes = await this.db.allDocs<Athlete>({
      include_docs: true,
      startkey: `ath-${clubId}`,
      endkey: `ath-${clubId}\ufff0`,
    });
    await this.db.bulkDocs([
      ...athletes.rows.map((a) => ({
        _id: a.id,
        _rev: a.doc?._rev,
        _deleted: true,
      })),
    ]);
  }
}

import { Select } from "antd";
import { useMembership } from "../../app/athleteIist";

export const MembershipForm = () => {
  const [membershipOptions, doSelectMembership, selectedMembership] =
    useMembership();
  return (
    <>
      <div className="flex flex-col gap-8px">
        <div className="blockTitle">Select membership type</div>
        <div>
          <Select
            className={!selectedMembership ? "errorBlock" : ""}
            style={{ width: "400px" }}
            placeholder="Select"
            value={selectedMembership}
            onChange={doSelectMembership}
            options={membershipOptions}
          />
        </div>
      </div>
    </>
  );
};

export function declineWord(numeral: number, word: string) {
  // Special case: 11, 12, and 13 have irregular declensions
  if (numeral >= 11 && numeral <= 13) {
    return numeral + " " + word + "s";
  }

  // Handle the general declension rules
  if (numeral % 10 === 1) {
    return numeral + " " + word;
  } else {
    return numeral + " " + word + "s";
  }
}

export const stateObj = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const stateOptions = [
  { value: "AL", label: "AL Alabama" },
  { value: "AK", label: "AK Alaska" },
  { value: "AZ", label: "AZ Arizona" },
  { value: "AR", label: "AR Arkansas" },
  { value: "CA", label: "CA California" },
  { value: "CO", label: "CO Colorado" },
  { value: "CT", label: "CT Connecticut" },
  { value: "DE", label: "DE Delaware" },
  { value: "DC", label: "DC District Of Columbia" },
  { value: "FL", label: "FL Florida" },
  { value: "GA", label: "GA Georgia" },
  { value: "HI", label: "HI Hawaii" },
  { value: "ID", label: "ID Idaho" },
  { value: "IL", label: "IL Illinois" },
  { value: "IN", label: "IN Indiana" },
  { value: "IA", label: "IA Iowa" },
  { value: "KS", label: "KS Kansas" },
  { value: "KY", label: "KY Kentucky" },
  { value: "LA", label: "LA Louisiana" },
  { value: "ME", label: "ME Maine" },
  { value: "MD", label: "MD Maryland" },
  { value: "MA", label: "MA Massachusetts" },
  { value: "MI", label: "MI Michigan" },
  { value: "MN", label: "MN Minnesota" },
  { value: "MS", label: "MS Mississippi" },
  { value: "MO", label: "MO Missouri" },
  { value: "MT", label: "MT Montana" },
  { value: "NE", label: "NE Nebraska" },
  { value: "NV", label: "NV Nevada" },
  { value: "NH", label: "NH New Hampshire" },
  { value: "NJ", label: "NJ New Jersey" },
  { value: "NM", label: "NM New Mexico" },
  { value: "NY", label: "NY New York" },
  { value: "NC", label: "NC North Carolina" },
  { value: "ND", label: "ND North Dakota" },
  { value: "OH", label: "OH Ohio" },
  { value: "OK", label: "OK Oklahoma" },
  { value: "OR", label: "OR Oregon" },
  { value: "PA", label: "PA Pennsylvania" },
  { value: "RI", label: "RI Rhode Island" },
  { value: "SC", label: "SC South Carolina" },
  { value: "SD", label: "SD South Dakota" },
  { value: "TN", label: "TN Tennessee" },
  { value: "TX", label: "TX Texas" },
  { value: "UT", label: "UT Utah" },
  { value: "VT", label: "VT Vermont" },
  { value: "VA", label: "VA Virginia" },
  { value: "WA", label: "WA Washington" },
  { value: "WV", label: "WV West Virginia" },
  { value: "WI", label: "WI Wisconsin" },
  { value: "WY", label: "WY Wyoming" },
];

export const dobToGraduationYear = (dob?: string) => {
  if (!dob) return undefined;
  const [mm, _dd, yyyy] = dob.split("/");
  if (!yyyy) {
    return undefined;
  }
  return `${+yyyy + (+mm >= 9 ? 19 : 18)}`;
};

import dayjs, { Dayjs } from "dayjs";
import { P, isMatching, match } from "ts-pattern";
import { dobToGraduationYear, stateObj } from "../shared/util";

export type AthleteId = string;

export type Gender = "m" | "f";

export type RsportzAthleteError = {
  athlete: Athlete;
  error: string;
};

/**
 * Gender guard
 * @param g
 * @returns
 */
const isGender = (g: string): g is Gender =>
  match(g)
    .with("m", "f", () => true)
    .otherwise(() => false);

/**
 * Gender constructor
 * @param g
 * @returns
 */
const makeGender = (g?: string): Gender | undefined => {
  if (!g || !g.trim()) return undefined;
  const gl = g.trim().toLowerCase()[0];
  return isGender(gl) ? gl : undefined;
};

export type Athlete = {
  _id?: AthleteId;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  dob?: DateStr;
  graduationYear?: string;
  gender?: Gender;
  email?: string;
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  // phoneNumber?: string;
  parentFirstName?: string;
  parentPhone?: string;
  parentLastName?: string;
  country?: string;
  // parentEmail?: string;
};

export const isAthlete = isMatching({
  firstName: P.intersection(
    P.string,
    P.when((data) => (data as string).length > 0)
  ),
  lastName: P.intersection(
    P.string,
    P.when((data) => (data as string).length > 0)
  ),
});

type PureAthlete = Omit<Athlete, "_id">;

const csvLabels: Record<string, keyof PureAthlete> = {
  firstname: "firstName",
  lastname: "lastName",
  middlename: "middleName",
  "dobmm/dd/yyyy": "dob",
  "dobmm/dd/yy": "dob",
  dob: "dob",
  birthdate: "dob",
  birthday: "dob",
  dayofbirth: "dob",
  gendermorf: "gender",
  gender: "gender",
  birthgender: "gender",
  emailaddress: "email",
  email: "email",
  streetaddress: "address",
  address: "address",
  city: "city",
  state: "state",
  zipcode12345: "zipcode",
  zip: "zipcode",
  zipcode: "zipcode",
  "phonenumber123-123-1234": "parentPhone",
  phonenumber: "parentPhone",
  phone: "parentPhone",
  guardianphone: "parentPhone",
  parentphone: "parentPhone",
  guardianfirstname: "parentFirstName",
  parentfirstname: "parentFirstName",
  guardianlastname: "parentLastName",
  parentlastname: "parentLastName",
};

export const athleteLabel: Record<keyof PureAthlete, string> = {
  firstName: "First Name",
  lastName: "Last Name",
  middleName: "Middle Name",
  dob: "Birthday",
  graduationYear: "Graduation Year",
  gender: "Gender",
  email: "Guardian Email",
  address: "Address",
  city: "City",
  state: "State",
  zipcode: "Zip Code",
  parentFirstName: "Guardian First Name",
  parentPhone: "Guardian Phone",
  parentLastName: "Guardian Last Name",
  country: "Country",
};

const reqFields = [
  "firstName",
  "lastName",
  "dob",
  "gender",
  "email",
  "address",
  "city",
  "state",
  "zipcode",
  "parentFirstName",
  "parentPhone",
  "parentLastName",
];

export const getAthleteLabel = (key: keyof PureAthlete) => athleteLabel[key];

export type AthleteList = Array<Athlete>;

/**
 * Get Athlete name
 * @param a
 * @returns
 */
export const getAthleteName = (a: Partial<Athlete>) =>
  [a.firstName, a.middleName, a.lastName].filter(Boolean).join(" ");

export const getAthleteInfo = (a: Partial<Athlete>) =>
  [a.dob, a.gender, a.email].filter(Boolean).join(", ");

export const getGenderName = (g?: Gender) =>
  match(g)
    .with("f", () => "Female")
    .with("m", () => "Male")
    .otherwise(() => "");

export const getParentName = (a: Partial<Athlete>) =>
  [a.parentFirstName, a.parentLastName].filter(Boolean).join(" ");

// Validators -------------

// https://stackoverflow.com/a/13178771
const emailReg =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

const req = Boolean;
const validateEmail = (str?: string) => req(str) && emailReg.test(String(str!));

// state has 2 symbols
const validateState = (str?: string) => req(str) && str?.length === 2;

// zip has 5 symbols
const validateZip = (str?: string) => req(str) && str?.length === 5;

// phone has 10 symbols
// ignore first symbol, if it = 1 (us phone code)
export const validatePhone = (str?: string) =>
  req(str) && str?.replace(/[^\d]/g, "").replace(/^1/, "").length === 10;

// check age
export const getAgeValidationDates = (): Readonly<[Dayjs, Dayjs]> =>
  [dayjs().add(-19, "y"), dayjs().add(-3, "y")] as const;

export const isValidAge = (str: string) => {
  const dt = dayjs(str!, "MM/DD/YYYY");
  const dtlimit = getAgeValidationDates();
  return dt.isAfter(dtlimit[0]) && dt.isBefore(dtlimit[1]);
};

const validateDob = (str?: string) =>
  req(str) && dayjs(str!, "MM/DD/YYYY", true).isValid() && isValidAge(str!);

const validateName = (str?: string) =>
  req(str) && /^[A-Za-z'\- ]+$/.test(String(str!));

const validator: Record<keyof PureAthlete, (str?: string) => boolean> = {
  firstName: validateName,
  lastName: validateName,
  middleName: () => true,
  dob: validateDob,
  graduationYear: () => true,
  gender: req, //TODO: add validator
  email: validateEmail,
  address: req, //TODO: add validator
  city: req, //TODO: add validator
  state: validateState,
  zipcode: validateZip,
  parentFirstName: validateName,
  parentPhone: validatePhone,
  parentLastName: validateName,
  country: () => true,
};

/**
 * Get athlete error fields
 * @param a
 * @returns
 */
export const getAthleteInvalidFields = (a: Partial<Athlete>) =>
  Object.entries(validator)
    .map(([field, isOk]) =>
      isOk(a[field as keyof PureAthlete])
        ? null
        : getAthleteLabel(field as keyof PureAthlete)
    )
    .filter(Boolean) as string[];

/**
 * Validate Athlete for ready to submit
 * @param a
 * @returns
 */
export const isValidAthlete = (a: Partial<Athlete>) =>
  Object.entries(validator).every(([field, isOk]) =>
    isOk(a[field as keyof PureAthlete])
  );

/**
 * Validate Athlete list for ready to submit
 * @param as
 * @returns
 */
export const isValidAthleteList = (as: AthleteList) =>
  as && as.length > 0 && as.every(isValidAthlete);

// trim and remove non unicode
const fixStr = (str?: string) => {
  if (typeof str === "undefined") return undefined;

  return str.trim().replace(/[\u{0080}-\u{FFFF}]/gu, "");
};

const makeDob = (str?: string) => {
  if (!str) {
    return undefined;
  }
  const dstr = fixStr(str);
  if (dayjs(dstr, "MM/DD/YYYY", true).isValid()) {
    return dstr;
  }
  if (dayjs(dstr, "M/DD/YYYY", true).isValid()) {
    return dayjs(dstr, "M/DD/YYYY").format("MM/DD/YYYY");
  }
  if (dayjs(dstr, "M/D/YYYY", true).isValid()) {
    return dayjs(dstr, "M/D/YYYY").format("MM/DD/YYYY");
  }
  if (dayjs(dstr, "MM.DD.YYYY", true).isValid()) {
    return dayjs(dstr, "MM.DD.YYYY").format("MM/DD/YYYY");
  }
  return undefined;
};

const makeGraduationYear = (
  _?: string,
  athleteData?: Partial<Record<keyof PureAthlete, string | undefined>>
) => {
  const dob = makeDob(athleteData?.dob);
  if (!dob) {
    return undefined;
  }
  return dobToGraduationYear(dob);
};

const makeState = (str?: string) => {
  if (!str || !(str.trim().length === 2)) {
    return undefined;
  }
  const st = str.trim().toUpperCase();
  if (st in stateObj) {
    return st;
  }
  return undefined;
};

const makeGuardianFirst = (name?: string) => {
  const fName = fixStr(name);
  return fName || "Guardian";
};

const makeGuardianLast = (
  name?: string,
  athleteData?: Partial<Record<keyof PureAthlete, string | undefined>>
) => {
  const lName = fixStr(name);
  if (lName) return lName;
  return fixStr(athleteData?.lastName);
};

// Field constructors ---------------
const fieldFactory: Record<
  keyof PureAthlete,
  (
    str?: string,
    athleteData?: Partial<Record<keyof PureAthlete, string | undefined>>
  ) => string | undefined
> = {
  firstName: fixStr,
  lastName: fixStr,
  middleName: fixStr,
  dob: makeDob,
  graduationYear: makeGraduationYear,
  gender: makeGender,
  email: fixStr, //TODO: add constructor
  address: fixStr, //TODO: add constructor
  city: fixStr, //TODO: add constructor
  state: makeState,
  zipcode: fixStr, //TODO: add constructor
  // phoneNumber: fixStr, //TODO: add constructor
  parentFirstName: makeGuardianFirst,
  parentPhone: fixStr, //TODO: add constructor
  parentLastName: makeGuardianLast,
  country: fixStr,
};

export const fixAthlete = (a: Athlete) => ({
  ...a,
  ...makeAthlete(a),
});

/**
 * Athlete constructor
 * @param clubId
 * @param athleteData
 * @returns
 */
export const makeAthlete = (
  athleteData: Partial<Record<keyof PureAthlete, string | undefined>>
): Athlete => ({
  country: "US", // TODO брать из списка
  ...Object.entries(fieldFactory).reduce(
    (obj, [field, make]) => ({
      ...obj,
      [field]: make(athleteData[field as keyof PureAthlete], athleteData),
    }),
    {}
  ),
});

/**
 * Compare 2 athletes to remove dublicates (by fname, lname, dob)
 * @param a1
 * @param a2
 * @returns boolean
 */
const isEqualAthletes = (a1: Partial<Athlete>) => (a2: Partial<Athlete>) =>
  a1.firstName === a2.firstName &&
  a1.lastName === a2.lastName &&
  a1.dob === a2.dob;

/**
 * Check that list already has this athlete
 * @param athlete
 * @param list
 * @returns
 */
const hasDublicate = (athlete: Athlete, list: AthleteList) => {
  const eq = isEqualAthletes(athlete);
  return list.filter(eq).length > 0;
};

/**
 * check if Athlete data is not enough
 * @param a
 * @returns
 */
const isEmptyAthlete = (a?: Partial<Athlete>) =>
  !a || (!a.firstName && !a.lastName);

/**
 * Add athlete to list
 * @param athlete
 * @param list
 * @returns
 */
export const addAthleteToList = (
  athlete: Athlete,
  list: AthleteList
): AthleteList =>
  isEmptyAthlete(athlete) || hasDublicate(athlete, list)
    ? list
    : [...list, athlete];

const normCsvField = (key: string) =>
  key.trim().toLowerCase().replace(/\s/g, "");

export const findCsvMissingFields = (data: unknown): string[] => {
  if (data && typeof data === "object") {
    const notFound = new Set(reqFields);
    const csvFields = Object.keys(data).map(normCsvField);

    for (const [k, v] of Object.entries(csvLabels)) {
      if (csvFields.includes(k)) {
        notFound.delete(v);
      }
    }
    return Array.from(notFound.values()).map((f) => getAthleteLabel(f as any));
  }
  return [];
};

export const hasInvalidDob = (aths: AthleteList) =>
  aths.some((ath) => !ath.dob);

/**
 * Make athlete from csv
 * @param data
 * @returns
 */
const makeAthleteFromCsv = (data: unknown): Athlete | null => {
  if (data && typeof data === "object") {
    const a = Object.entries(data).reduce(
      (obj, [key, val]) => ({
        ...obj,
        [csvLabels[normCsvField(key)]]: val,
      }),
      {}
    );
    if (isAthlete(a)) return a;
  }
  return null;
};

/**
 * athlete list constructor
 * @param clubId
 * @param data
 * @returns
 */
export const makeAthleteList = (data: Array<unknown>) => {
  let list: AthleteList = [];
  data.forEach((d) => {
    const ath = makeAthleteFromCsv(d);
    if (ath) {
      list = addAthleteToList(
        makeAthlete({
          ...ath,
        }),
        list
      );
    }
  });

  return list;
};

/**
 * Get Athlete from list
 * @param athleteId
 * @param aths
 * @returns
 */
export const getAthleteFromList = (athleteId: AthleteId, aths: AthleteList) =>
  aths.find((a) => a._id === athleteId);

/**
 * Type for AthleteTable
 */
export type ListViewItem = {
  key: string;
  name: string;
  gender: string;
  dob: string;
  parentName: string;
  parentPhone: string;
  parentEmail: string;
  addr: string;
  errorFields: string[];
  isValid: boolean;
  graduationYear: string;
};

/*
"First Name"
"Last Name"
"Birthday"
"Gender"
"Email"
"Address"
"Phone"
"Parent First Name"
"Parent Phone"
"Parent Last Name"
"Parent Email"

 */

// const invalidClassForList = (errs: string[]) => ({
//   name:
//     ".athName" +
//     (errs.includes("First Name") || errs.includes("Last Name")
//       ? ".errField"
//       : ""),
//   gender: ".athGender" + errs.includes("Gender") ? ".errField" : "",
//   dob: "athDob" + errs.includes("Birthday") ? ".errField" : "",
//   parentName:
//     ".pName" +
//     (errs.includes("Parent First Name") || errs.includes("Parent Last Name")
//       ? ".errField"
//       : ""),
//   parentPhone: ".pPhone" + errs.includes("Parent Phone") ? ".errField" : "",
//   parentEmail: ".pEmail" + errs.includes("Parent Email") ? ".errField" : "",
//   addr: ".athAddr" + errs.includes("Address") ? ".errField" : "",
// });

export const makeListViewItem = (a: Athlete): ListViewItem => ({
  key: a._id!,
  name: getAthleteName(a),
  gender: getGenderName(a.gender),
  dob: a.dob || "",
  parentName: getParentName(a),
  parentPhone: a.parentPhone || "",
  parentEmail: a.email || "",
  addr: a.address || "",
  isValid: isValidAthlete(a),
  errorFields: getAthleteInvalidFields(a),
  graduationYear: a.graduationYear || "",
});

export const filterViewItemByErrors =
  (hasError: boolean) => (list: ListViewItem[]) =>
    !hasError ? list : list.filter((i) => !i.isValid);

export const filterViewItemByEmail =
  (search: string) => (list: ListViewItem[]) =>
    !search ? list : list.filter((i) => i.parentEmail.includes(search));

export const makeAthleteForRsportz = (country: string) => (ath: Athlete) => ({
  ...ath,
  country,
});

// TESTS **********************************************************************
if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest;
  // makeGender
  it("makeGender", () => {
    expect(makeGender("m")).toBe("m");
    expect(makeGender("f")).toBe("f");
    expect(makeGender("M")).toBe("m");
    expect(makeGender("F")).toBe("f");
    expect(makeGender()).toBeUndefined();
    expect(makeGender("a")).toBeUndefined();
  });
  // isEqualAthletes
  it("isEqualAthletes", () => {
    expect(
      isEqualAthletes({
        firstName: "a",
        lastName: "b",
        dob: "1-2-3",
        email: "aaa@bbb",
      })({
        firstName: "a",
        lastName: "b",
        dob: "1-2-3",
        email: "sds@bbsdsb",
      })
    ).toBeTruthy();
    expect(
      isEqualAthletes({
        firstName: "a",
        lastName: "b",
        dob: "1-2-3",
        email: "aaa@bbb",
      })({
        firstName: "a",
        lastName: "b",
        dob: "1-2-4",
        email: "sds@bbsdsb",
      })
    ).toBeFalsy();
    expect(
      isEqualAthletes({})({
        firstName: "a",
        lastName: "b",
        dob: "1-2-4",
        email: "sds@bbsdsb",
      })
    ).toBeFalsy();
  });
  // makeAthlete
  it("makeAthlete", () => {
    expect(
      makeAthlete({
        firstName: "firstName",
        lastName: "lastName",
        middleName: "middleName",
        dob: "dob",
        gender: "M",
        email: "email",
        address: "address",
        city: "city",
        state: "state",
        zipcode: "zipcode",
      }).firstName
    ).toBe("firstName");
  });
  // isEmptyAthlete
  it("isEmptyAthlete", () => {
    expect(isEmptyAthlete({ firstName: "a" })).toBeFalsy();
    expect(isEmptyAthlete({})).toBeTruthy();
  });
  // hasDublicate
  it("hasDublicate", () => {
    expect(
      hasDublicate(
        {
          firstName: "a",
          lastName: "b",
          dob: "1-2-4",
          email: "sds@bbsdsb",
        },
        []
      )
    ).toBeFalsy();
    expect(
      hasDublicate(
        {
          firstName: "a",
          lastName: "b",
          dob: "1-2-4",
          email: "sds@bbsdsb",
        },
        [
          {
            firstName: "a",
            lastName: "bs",
            dob: "1-2-4",
            email: "sds@bbsdsb",
          },
        ]
      )
    ).toBeFalsy();
    expect(
      hasDublicate(
        {
          firstName: "a",
          lastName: "b",
          dob: "1-2-4",
          email: "sds@bbsdsb",
        },
        [
          {
            firstName: "a",
            lastName: "b",
            dob: "1-2-4",
            email: "sds@bbsdsb",
          },
        ]
      )
    ).toBeTruthy();
    // addAthleteToList
    it("addAthleteToList", () => {
      expect(addAthleteToList({}, []).length).toBe(0);
      expect(
        addAthleteToList(
          {
            firstName: "a",
            lastName: "b",
            dob: "1-2-4",
            email: "sds@bbsdsb",
          },
          [
            {
              firstName: "a",
              lastName: "b",
              dob: "1-2-4",
              email: "sds@bbsdsb",
            },
          ]
        ).length
      ).toBe(1);
      expect(
        addAthleteToList(
          {
            firstName: "a",
            lastName: "b",
            dob: "1-2-4",
            email: "sds@bbsdsb",
          },
          [
            {
              firstName: "ab",
              lastName: "b",
              dob: "1-2-4",
              email: "sds@bbsdsb",
            },
          ]
        ).length
      ).toBe(2);
    });
  });
  // getAthleteName
  it("getAthleteName", () => {
    expect(getAthleteName({ firstName: "a", lastName: "b" })).toBe("a b");
    expect(
      getAthleteName({ firstName: "a", lastName: "b", middleName: "c" })
    ).toBe("a c b");
    expect(getAthleteName({ firstName: "a" })).toBe("a");
  });
  // getAthleteInfo
  it("getAthleteInfo", () => {
    expect(getAthleteInfo({ firstName: "a", lastName: "b" })).toBe("");
    expect(
      getAthleteInfo({
        firstName: "a",
        lastName: "b",
        middleName: "c",
        email: "aaa@bbb",
      })
    ).toBe("aaa@bbb");
    expect(
      getAthleteInfo({ firstName: "a", email: "aaa@bbb", gender: "f" })
    ).toBe("f, aaa@bbb");
  });
  // validateEmail
  it("validateEmail", () => {
    expect(validateEmail("")).toBeFalsy();
    expect(validateEmail("a@b")).toBeFalsy();
    expect(validateEmail("a@.c")).toBeFalsy();
    expect(validateEmail("a@b.")).toBeFalsy();
    expect(validateEmail("@b.c")).toBeFalsy();
    expect(validateEmail("a@b@c.c")).toBeFalsy();
    expect(validateEmail("a@b@.c")).toBeFalsy();
    expect(validateEmail("ab.c")).toBeFalsy();
    expect(validateEmail("ab")).toBeFalsy();
    expect(validateEmail("a.b@.c")).toBeFalsy();
    expect(validateEmail("a.b@q.c")).toBeTruthy();
    expect(validateEmail("a.b@q.s.c")).toBeTruthy();
    expect(validateEmail("a@b.c")).toBeTruthy();
    expect(validateEmail("a-s@b.c")).toBeTruthy();
    expect(validateEmail("тест@b.c")).toBeTruthy();
  });

  // isValidAthlete
  it("isValidAthlete", () => {
    expect(isValidAthlete({})).toBeFalsy();
    expect(
      isValidAthlete({
        firstName: "firstName",
        lastName: "lastName",
        dob: "dob",
        gender: "m",
        email: "email@sdsd.sdd",
        address: "address",
        city: "city",
        state: "state",
        zipcode: "zipcode",
        parentFirstName: "q",
        parentLastName: "aa",
        parentPhone: "2222",
      })
    ).toBeTruthy();
  });
  // isValidAthleteList
  it("isValidAthleteList", () => {
    expect(isValidAthleteList([] as AthleteList)).toBeFalsy();
    expect(
      isValidAthleteList([
        {
          firstName: "firstName",
          lastName: "lastName",
          dob: "dob",
          gender: "m",
          email: "email@sdsd.sdd",
          address: "address",
          city: "city",
          state: "state",
          zipcode: "zipcode",
          parentFirstName: "q",
          parentLastName: "aa",
          parentPhone: "2222",
        },
      ] as AthleteList)
    ).toBeTruthy();
  });
  // getAthleteFromList
  it("getAthleteFromList", () => {
    expect(
      getAthleteFromList("3", [
        {
          _id: "3",
          firstName: "firstName",
          lastName: "lastName",
          dob: "dob",
          gender: "m",
          email: "email@sdsd.sdd",
          address: "address",
          city: "city",
          state: "state",
          zipcode: "zipcode",
        },
      ] as AthleteList)?.lastName
    ).toBe("lastName");
  });

  // isAthlete
  it("isAthlete", () => {
    expect(isAthlete("")).toBeFalsy();
    expect(isAthlete(undefined)).toBeFalsy();
    expect(isAthlete({})).toBeFalsy();
    expect(isAthlete({ firstName: "a" })).toBeFalsy();
    expect(isAthlete({ firstName: "a", lastName: "b" })).toBeTruthy();
    expect(isAthlete({ firstName: "", lastName: "b" })).toBeFalsy();
    expect(isAthlete({ firstName: undefined, lastName: "b" })).toBeFalsy();
    expect(isAthlete({ firstName: null, lastName: "b" })).toBeFalsy();
  });

  // makeAthleteList
  it("makeAthleteList", () => {
    expect(makeAthleteList([]).length).toBe(0);
    expect(
      makeAthleteList([{ "First Name": "a", "Last Name": "b" }]).length
    ).toBe(1);

    expect(
      makeAthleteList([
        { "First Name": "a", "Last Name": "b" },
        { "First Name": "a", "Last Name": "b" },
      ]).length
    ).toBe(1);
    expect(
      makeAthleteList([{ "First Name": "a", "Last Name": "b" }])[0].firstName
    ).toBe("a");
  });

  // makeAthleteFromCsv
  it("makeAthleteFromCsv", () => {
    expect(makeAthleteFromCsv(undefined)).toBeNull();
    expect(
      makeAthleteFromCsv({ "First Name": "a", "Last Name": "b" })?.firstName
    ).toBe("a");
  });
}

import { Form, Modal } from "antd";
import { useAddAthlete, useEditAthlete } from "../../app/athleteIist";
import type { Athlete } from "../../domain/athltetes";
import type { ModalProps } from "../../shared/ui/modal";
import { AthleteFields } from "./athleteFields";

const modalProps = {
  // footer: false,
  open: true,
  width: 600,
};

export const EditAthleteModal = ({
  onClose,
  data,
}: ModalProps<Athlete, Athlete>) => {
  const [form] = Form.useForm<Athlete>();
  const [doEditAthlete] = useEditAthlete();
  return (
    <Modal
      {...modalProps}
      title="Athlete personal information"
      okText="Update Athlete"
      cancelText="Cancel"
      width={450}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      afterOpenChange={() => form.validateFields()}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            doEditAthlete!({ athlete: { ...data, ...values } });
            onClose();
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="athleteEditForm"
        initialValues={data}
      >
        <AthleteFields form={form} />
      </Form>
    </Modal>
  );
};

export const AddAthleteModal = ({ onClose }: ModalProps<Athlete>) => {
  const [form] = Form.useForm<Athlete>();
  const [doAddAthlete] = useAddAthlete();
  return (
    <Modal
      {...modalProps}
      title="Athlete personal information"
      okText="Add Athlete"
      cancelText="Cancel"
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            doAddAthlete!({ athlete: values });
            onClose();
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="athleteAddForm">
        <AthleteFields form={form} />
      </Form>
    </Modal>
  );
};

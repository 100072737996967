import type { EffectCallback } from "react";
import { useEffect, useRef } from "react";

/**
 * Prevent double call use effect on strict mode (https://taig.medium.com/prevent-react-from-triggering-useeffect-twice-307a475714d7)
 * @param effect
 */
export function useOnMountUnsafe(effect: EffectCallback) {
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      effect();
    }
  }, []);
}

import { P, isMatching } from "ts-pattern";
import type { Club } from "./club";

// export type Auth = {
//   userId: string;
//   clubId: string;
// };

export const isAuth = isMatching({ clubId: P.number });

export type AuthState =
  | { status: "idle" }
  | { status: "loading" }
  | { status: "success"; data: Club }
  | { status: "error"; message: string }
  | { status: "rsportz"; data?: Club };

export const makeAuthErrorState = (message: string) =>
  ({ status: "error", message } as AuthState);

export const makeAuthSuccessState = (data: unknown) =>
  (isAuth(data)
    ? { status: "success", data: data as Club }
    : makeAuthErrorState("Server Data Error")) as AuthState;

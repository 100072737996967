import {
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  UserAddOutlined,
  UsergroupDeleteOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Checkbox,
  Input,
  Popconfirm,
  Popover,
  Result,
} from "antd";
import { useCallback } from "react";
import {
  useClearAthleteList,
  useDeleteAthlete,
  useErrorFilter,
  useGetList,
  useGetListInfo,
  useMissingFields,
  useSearch,
  useSelectAthlete,
  useSelectedAthlete,
} from "../../app/athleteIist";
import { createUseModal } from "../../shared/ui/modal";
import { AddAthleteModal, EditAthleteModal } from "./athleteModal";

import useVirtual from "react-cool-virtual";
import type { ListViewItem } from "../../domain/athltetes";
import { declineWord } from "../../shared/util";
import type { CheckboxChangeEvent } from "antd/es/checkbox";

const AthleteListName = ({ name }: { name: string }) => (
  <div className="listItem_name">{name}</div>
);
const AthleteListDescription = ({
  description,
  errors,
}: {
  description: string;
  errors: string[];
}) => (
  <>
    <div className="listItem_desc">{description}</div>
    {errors.length > 0 && (
      <div className="listItem_errors">
        Incorrect Fields: {errors.join(", ")}
      </div>
    )}
  </>
);

const useAddModal = createUseModal<void, string>(AddAthleteModal);

export const AddAthltete = () => {
  const [addModal, showModal] = useAddModal();
  return (
    <>
      <Button className="text-[#293886]" onClick={() => showModal("")}>
        Add new athlete manually
      </Button>
      {addModal()}
    </>
  );
};

export const EditAthltete = () => {
  const [doClose, selected] = useSelectedAthlete();
  if (!selected) return null;
  return (
    <>
      <EditAthleteModal data={selected} onClose={() => doClose(undefined)} />
    </>
  );
};

export const DeleteAllAthltetes = () => {
  const [doClear] = useClearAthleteList();
  const [len, isLoading] = useGetListInfo();
  return (
    <>
      <Popconfirm
        title="Clear Athlete List?"
        description="Are you sure to delete all athletes?"
        onConfirm={doClear}
        okText="Clear athlete list"
        cancelText="Cancel"
        okButtonProps={{
          danger: true,
        }}
        icon={<QuestionCircleOutlined style={{ color: "red" }} />}
      >
        <Button
          loading={isLoading}
          disabled={len === 0}
          className="w-120px"
          danger
        >
          Clear List
        </Button>
      </Popconfirm>
    </>
  );
};

type ItemProps = {
  num: number;
  athlete: ListViewItem;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
};

const ListItem = ({ num, athlete, onDelete, onEdit }: ItemProps) => {
  if (!athlete) return null;
  const {
    isValid,
    gender,
    dob,
    parentName,
    parentPhone,
    parentEmail,
    addr,
    errorFields,
    key: id,
    name,
    graduationYear,
  } = athlete;
  return (
    <div className="athItem">
      <div className="athNumRow">
        {isValid ? (
          <div className={`athNum`}>{num}</div>
        ) : (
          <Popover content={errorFields.join(", ")} title="Errors">
            <Badge offset={[35, 0]} count={errorFields.length}>
              <div className={`athNum athNum-error`}>{num}</div>
            </Badge>
          </Popover>
        )}
      </div>

      <div className="athDataRow">
        <div className="athNameCol">
          <div className={"athName"}>{name}</div>
          <div className={"athGender"}>{gender}</div>
          <div className={"athDob"}>{dob}</div>
        </div>
      </div>

      <div className="athActionRow">
        <button className="editUser" key="edit" onClick={() => onEdit(id)}>
          <EditOutlined />
        </button>

        <button
          className="deleteUser"
          key="delete"
          onClick={() => onDelete(id)}
        >
          <DeleteOutlined />
        </button>
      </div>

      <div></div>
      <div>
        <div className="athAddCol">
          <div className="athGuard">
            <div className="pName">{parentName}</div>
            <div className="pPhone">{parentPhone}</div>
            {!!parentEmail && (
              <a href={`mailto:${parentEmail}`} className="pEmail">
                {parentEmail}
              </a>
            )}
          </div>
          <div className="athAddr">{addr}</div>
          <div className="athGraduation">{graduationYear}</div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

const Search = ({ found }: { found: number }) => {
  const [doSearch] = useSearch();
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    doSearch(e.target.value);
  };
  return (
    <div className="flex mt-16px mb-15px items-center gap-10px">
      <Input
        className="w-300px"
        addonBefore={<SearchOutlined />}
        placeholder="Search by email"
        onChange={onChange}
      />
      <div className="text-[#A4A4A4]">
        Found {declineWord(found, "Athlete")}
      </div>
    </div>
  );
};

const ErrorFilter = () => {
  const [doErrorFilter, checked] = useErrorFilter();
  const onChange = (e: CheckboxChangeEvent) => {
    doErrorFilter(e.target.checked);
  };
  return (
    <label className="flex mt-16px mb-15px items-center gap-10px">
      <Checkbox checked={checked} onChange={onChange} />
      <div>Show errors only</div>
      {/* <div className="text-[#A4A4A4]">
        Found {declineWord(found, "Athlete")}
      </div> */}
    </label>
  );
};

const VirtAthList = () => {
  const [athleteList, isLoading, len] = useGetList();
  const [doDelete] = useDeleteAthlete();

  const [doSelect] = useSelectAthlete();
  const deleteHandle = useCallback(
    (athleteId: string) => doDelete({ athleteId }),
    [doDelete]
  );
  const editModalHandle = useCallback(
    (athleteId: string) => doSelect(athleteId),
    [doSelect]
  );
  const { outerRef, innerRef, items } = useVirtual({
    resetScroll: true,
    itemCount: len, // Provide the total number for the list items
    itemSize: 124, // The size of each item (default = 50)
  });
  // if (athleteList.length === 0)
  //   return (
  //     <div className="flex justify-center mt-16px mb-15px ">
  //       No athletes found
  //     </div>
  //   );

  return (
    <div className="w-full mt-16px ">
      <div className="w-full flex gap-20px justify-between">
        <Search found={len} />

        <ErrorFilter />
      </div>
      {len === 0 ? (
        <div className="flex justify-center mb-15px ">No athletes found</div>
      ) : (
        <div
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ref={outerRef!} // Attach the `outerRef` to the scroll container
          // style={{ width: "1113px", height: "620px", overflow: "auto" }}
          style={{ width: "1113px", height: "620px", overflow: "auto" }}
        >
          {/* Attach the `innerRef` to the wrapper of the items */}

          <div
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={innerRef!}
          >
            {items.map(({ index, size, measureRef }) => (
              // You can set the item's height with the `size` property
              <div key={index} style={{ height: `${size}px` }}>
                <ListItem
                  num={index + 1}
                  athlete={athleteList[index]}
                  onDelete={deleteHandle}
                  onEdit={editModalHandle}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

// const AthList = () => {
//   const [athleteList, isLoading] = useGetList();
//   const [doDelete] = useDeleteAthlete();

//   const [doSelect] = useSelectAthlete();
//   const deleteHandle = useCallback(
//     (athleteId: number) => () => doDelete({ athleteId }),
//     [doDelete]
//   );
//   const editModalHandle = useCallback(
//     (athleteId: number) => () => doSelect(athleteId),
//     [doSelect]
//   );
//   return (
//     <List
//       locale={{ emptyText: "Please upload CSV file or add athletes manually" }}
//       loading={isLoading}
//       itemLayout="horizontal"
//       dataSource={athleteList}
//       renderItem={({ key: athleteId, name, info, isValid, errorFields }) => (
//         <List.Item
//           className={!isValid ? "bg-red-100" : ""}
//           actions={[
//             <Button key="edit" type="link" onClick={editModalHandle(athleteId)}>
//               edit
//             </Button>,
//             <Button
//               key="delete"
//               danger
//               type="link"
//               onClick={deleteHandle(athleteId)}
//             >
//               delete
//             </Button>,
//           ]}
//         >
//           <List.Item.Meta
//             className="pl-5"
//             title={<AthleteListName name={name} />}
//             description={
//               <AthleteListDescription description={info} errors={errorFields} />
//             }
//           />
//         </List.Item>
//       )}
//     />
//   );
// };

const ShowMissingFields = () => {
  const [_, missing, doCancel, doOk] = useMissingFields();
  return (
    <Result
      title={
        <div>
          Your CSV file is missing some necessary fields: {missing.join(", ")}
        </div>
      }
      extra={
        <div className="text-16px">
          <p>
            Please, check our{" "}
            <a className="headerCSV" target="__blank" href="/example.csv">
              csv template
            </a>{" "}
            and upload correct file. Or skip this message and continue with
            current data.
          </p>
          <div className="flex gap-10px justify-center">
            <Button type="primary" onClick={doCancel}>
              Upload New CSV
            </Button>
            <Button onClick={doOk}>Skip</Button>
          </div>
        </div>
      }
    />
  );
};

export const AthletesTable = () => {
  const [hasMissing] = useMissingFields();
  if (hasMissing) return <ShowMissingFields />;
  return (
    <>
      {/* <AthList /> */}
      <VirtAthList />
      <EditAthltete />
    </>
  );
};

import {
  AutoComplete,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  type FormInstance,
} from "antd";
import { useState } from "react";
import { DateField, arrToOptions } from "../../shared/ui/form";
import { dobToGraduationYear, stateOptions } from "../../shared/util";
import {
  isValidAge,
  type Athlete,
  getAgeValidationDates,
  validatePhone,
} from "../../domain/athltetes";

const emails: Readonly<Array<string>> = [
  "rsportz.com",
  "yahoo.com",
  "hotmail.com",
  "gmail.com",
  "me.com",
  "aol.com",
  "mac.com",
  "live.com",
  "googlemail.com",
  "msn.com",
  "facebook.com",
  "verizon.net",
  "outlook.com",
  "icloud.com",
  "rediffmail.com",
  "zohomail.in",
  "zoho.com",
  "mail.com",
  "protonmail.com",
  "yandex.com",
];

const createFilterOption = (input: string, option?: { label?: string }) =>
  (option!.label! as string).toLowerCase().includes(input.toLowerCase());

const getEmailValue = (str: string) =>
  str.length < 3
    ? []
    : str.includes("@")
    ? emails.map((e) => ({ value: `${str.split("@")[0]}@${e}` }))
    : emails.map((e) => ({ value: `${str}@${e}` }));

export const AthleteFields = (props: { form: FormInstance<Athlete> }) => {
  const [options, setOptions] = useState<{ value: string }[]>([]);
  return (
    <>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Please input the athlete's first name!",
          },
          {
            pattern: /^[A-Za-z'\- ]+$/,
            message: "Please input name in correct format",
          },
        ]}
        name="firstName"
        label="First Name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Please input the athlete's last name!",
          },
          {
            pattern: /^[A-Za-z'\- ]+$/,
            message: "Please input name in correct format",
          },
        ]}
        name="lastName"
        label="Last Name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="middleName"
        label={
          <div>
            <span>Middle Name </span>
            <span className="text-[#BFBFBF]">&nbsp;Specify if there is</span>
          </div>
        }
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Please input the athlete's birthday!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value) {
                return Promise.resolve();
              }
              if (isValidAge(value)) {
                return Promise.resolve();
              }
              const [s, e] = getAgeValidationDates();
              return Promise.reject(
                new Error(
                  `The registering player does not meet the age eligibility requirement: between ${s.format(
                    "MM/DD/YYYY"
                  )} and ${e.format("MM/DD/YYYY")}`
                )
              );
            },
          }),
        ]}
        name="dob"
        label={
          <div>
            <span>Date of Birth </span>
            <span className="text-[#BFBFBF]">&nbsp;mm/dd/yyyy</span>
          </div>
        }
      >
        <DateField
          onChange={(value) =>
            props.form.setFieldValue(
              "graduationYear",
              dobToGraduationYear(value)
            )
          }
        />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Please input the athlete's graduation year!",
          },
        ]}
        name="graduationYear"
        label={
          <div>
            <span>Graduation Year</span>
          </div>
        }
        dependencies={["dob"]}
        shouldUpdate={(prevValues, curValues) =>
          prevValues.dob !== curValues.dob
        }
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Please input the athlete's gender!",
          },
        ]}
        name="gender"
        label="Birth Gender"
        className="pb-30px!"
      >
        <Radio.Group>
          <Radio value={"m"}>Male</Radio>
          <Radio value={"f"}>Female</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        rules={[
          {
            required: true,
            message: "Please input the guardian first name!",
          },
          {
            pattern: /^[A-Za-z'\- ]+$/,
            message: "Please input name in correct format",
          },
        ]}
        label="Guardian First Name"
        name="parentFirstName"
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Please input the guardian last name!",
          },
          {
            pattern: /^[A-Za-z'\- ]+$/,
            message: "Please input name in correct format",
          },
        ]}
        label="Guardian Last Name"
        name="parentLastName"
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Please input the guardian phone!",
          },

          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value) {
                return Promise.resolve();
              }
              if (validatePhone(value)) {
                return Promise.resolve();
              }

              return Promise.reject(
                new Error(
                  `Please input the guardian phone in the appropriate format!`
                )
              );
            },
          }),
        ]}
        label={
          <div>
            <span>Phone number </span>
            <span className="text-[#BFBFBF]">&nbsp;123-123-1234</span>
          </div>
        }
        name="parentPhone"
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Please input the guardian email!",
            type: "email",
          },
        ]}
        label="Email Address"
        name="email"
        className="pb-30px!"
      >
        <AutoComplete
          options={options}
          filterOption={true}
          onSearch={(text) => setOptions(getEmailValue(text))}
        />
      </Form.Item>
      {/* <Form.Item
        rules={[
          {
            required: true,
            message: "Please input the athlete's email!",
            type: "email",
          },
        ]}
        name="email"
        label="Email"
      >

        <AutoComplete
          options={options}
          filterOption={true}
          onSearch={(text) => setOptions(getEmailValue(text))}
        />
      </Form.Item> */}
      <Form.Item
        rules={[
          {
            required: true,
            message: "Please input the address!",
          },
        ]}
        name="address"
        label="Home street Address"
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Please input the city!",
          },
        ]}
        name="city"
        label="City"
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Please select the state!",
          },
        ]}
        name="state"
        label={
          <div>
            <span>State </span>
            <span className="text-[#BFBFBF]">&nbsp;AA</span>
          </div>
        }
      >
        <Select
          placeholder="Select your state"
          showArrow={true}
          showSearch={true}
          filterOption={createFilterOption}
          options={stateOptions}
        />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Please input the zip code!",
          },
          {
            pattern: /^\d{5}$/,
            message: "Please input zip in correct format",
          },
        ]}
        name="zipcode"
        label={
          <div>
            <span>ZIP </span>
            <span className="text-[#BFBFBF]">&nbsp;12345</span>
          </div>
        }
      >
        <Input className="w-80px" />
      </Form.Item>
      {/* <Form.Item
        rules={[
          {
            required: true,
            message: "Please input the athlete's phone number!",
          },
        ]}
        name="phoneNumber"
        label="Phone"
      >
        <Input />
      </Form.Item> */}
    </>
  );
};

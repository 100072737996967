import axios, { AxiosError, type AxiosInstance } from "axios";
import type { IHttpService } from "../app/ports";
import { captureException } from "@sentry/react";

export class UnauthorizedError extends Error {}
export class ForbiddenError extends Error {}

export const createServerError = (error: unknown) => {
  console.log("HTTP ERROR", error);
  if (error instanceof AxiosError) {
    throw new Error(error.response?.data?.msg || error?.message);
  } else if (error instanceof Error) {
    throw new Error(error?.message);
  } else {
    throw new Error("Server Error");
  }
};

const getToken = (queryString: string) => {
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get('token')
};

const isTest = (queryString: string) => {
  const urlParams = new URLSearchParams(queryString);
  return Boolean(urlParams.get('mode'));
};

export class HttpService implements IHttpService {
  private token: string;
  private http: AxiosInstance;
  constructor() {
    const queryString = window.location.search;
    this.token = getToken(queryString) || "";
    this.http = axios.create();
    this.http.interceptors.request.use(async (config) => {
      config!.headers!.Authorization = `Bearer ${this.token}`;
      if(isTest(queryString)){
        config!.headers!.mode = `test`;
      }
      return config;
    });
  }
  public async get<T>(url: string): Promise<T | undefined> {
    try {
      const { data } = await this.http.get<T>(url);
      return data;
    } catch (error) {
      createServerError(error);
    }
  }
  public async post<T, V>(url: string, data?: V): Promise<T | undefined> {
    try {
      const ret = await this.http.post<T>(url, data);
      return ret.data;
    } catch (error) {
      captureException(error);
      createServerError(error);
    }
  }
}

import { Button } from "antd";
import {
  useErrorFilter,
  useGetListInfo,
  useUploadAthleteList,
} from "../../app/athleteIist";
import { declineWord } from "../../shared/util";

export const SendToRsportz = () => {
  const [canUploadList, doUploadAthleteList, doShowErrors, isUploading] =
    useUploadAthleteList();

  const [doErrorFilter] = useErrorFilter();
  const [len, _, errs, isMembership] = useGetListInfo();
  return (
    <div className="flex gap-30px items-center text-16px">
      <div>
        <div
          className={`${len === 0 ? "text-[#C22329] font-bold" : ""}`}
        >{`${declineWord(len, "Athlete")}`}</div>
        {errs > 0 && (
          <Button
            type="link"
            onClick={() => doErrorFilter(true)}
            className="text-[#C22329] font-bold p0 m0"
          >{`${declineWord(errs, "Error")}`}</Button>
        )}
        {errs === 0 && !isMembership && (
          <div className="text-[#C22329] font-bold">{`Please Select Membership`}</div>
        )}
      </div>
      <Button
        className="w-160px"
        placeholder={
          canUploadList ? "Upload Bulk" : "Please fix all athletes errors!"
        }
        type="primary"
        loading={isUploading}
        // disabled={!canUploadList}
        onClick={canUploadList ? doUploadAthleteList : doShowErrors}
      >
        Upload Bulk
      </Button>
    </div>
  );
};

/**
 * Athlete List Use Cases
 */
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  sample,
} from "effector";
import { useStoreMap, useUnit } from "effector-react";
import { debounce, pending } from "patronum";
import { useEffect } from "react";
import {
  filterViewItemByEmail,
  getAthleteFromList,
  isValidAthleteList,
  makeListViewItem,
  type Athlete,
  type AthleteId,
  type AthleteList,
  type ListViewItem,
  getAthleteName,
  makeAthleteForRsportz,
  fixAthlete,
  filterViewItemByErrors,
  getAthleteLabel,
} from "../domain/athltetes";

import { mapSelectOption } from "../shared/ui/form";
import { err, msg } from "../shared/ui/notification";
import { $memberships, createClubFx } from "./auth";
import {
  doGetAthleteList,
  doSetMembership,
  doSetMissingFields,
  rsportzSent,
} from "./global";
import type { IRsportzService, IStorageService } from "./ports";
import { rsportzService, storageService } from "./services";

const storage: IStorageService = storageService;
const rsportz: IRsportzService = rsportzService;

const doAddAthlete = createEvent<{ athlete: Athlete }>();
const doDeleteAthlete = createEvent<{ athleteId: AthleteId }>();
const doEditAthlete = createEvent<{ athlete: Athlete }>();
const doUploadAthleteList = createEvent();
const doClearAthleteList = createEvent();

const doOkOnMissingFiels = createEvent();

const $missingFields = createStore<string[]>([])
  .on(doSetMissingFields, (_, data) => data)
  .reset(doOkOnMissingFiels);

export const useMissingFields = () =>
  useUnit([
    $missingFields.map((fs) => fs.length > 0),
    $missingFields,
    doClearAthleteList,
    doOkOnMissingFiels,
  ]);

const getListFx = createEffect(
  (data: Parameters<IStorageService["getAthleteList"]>[0]) =>
    storage.getAthleteList(data)
);
const addFx = createEffect(
  (data: Parameters<IStorageService["addAthlete"]>[0]) =>
    storage.addAthlete({ ...data, athlete: fixAthlete(data.athlete) })
);
const editFx = createEffect(({ athlete }: { athlete: Athlete }) =>
  storage.editAthlete(fixAthlete(athlete))
);
const deleteFx = createEffect(({ athleteId }: { athleteId: AthleteId }) =>
  storage.deleteAthlete(athleteId)
);
const clearFx = createEffect(
  async (data: Parameters<IStorageService["clearList"]>[0]) => {
    const list = await storage.getAthleteList(data);
    await storage.clearList(data);
    return list;
  }
);

/**
 * upload list effect
 */
const uploadFx = createEffect(
  async ({
    clubId,
    membershipId,
    country,
  }: {
    clubId: number;
    membershipId: number;
    country: string;
  }) => {
    const mapAth = makeAthleteForRsportz(country);
    // get athletes from db
    const athletes = (await storage.getAthleteList({ clubId })).map(mapAth);
    // upload to server
    return rsportz.sendAthletes({ athletes, membershipId });
  }
);

sample({
  clock: doGetAthleteList,
  target: createClubFx(getListFx),
});

err(getListFx.failData, "Database Error");

sample({
  clock: doAddAthlete,
  target: createClubFx(addFx),
});

err(addFx.failData, "Add Athlete Error");

sample({
  clock: doClearAthleteList,
  target: createClubFx(clearFx),
});

err(clearFx.failData, "Clear Athlete List Error");

const $listForError = createStore<{ name: string; email: string }[]>([]).on(
  clearFx.doneData,
  (_, data) =>
    data.map((a) => ({ name: getAthleteName(a), email: a.email || "" }))
);

sample({
  clock: doEditAthlete,
  target: editFx,
});

err(editFx.failData, "Edit Athlete Error");

sample({
  clock: doDeleteAthlete,
  target: deleteFx,
});

err(deleteFx.failData, "Delete Athlete Error");

const doSelectMembership = createEvent<number>();
const $selectedMembership = createStore<number | null>(null)
  .on(doSelectMembership, (_, m) => m)
  .on(doSetMembership, (_, m) => m);

sample({
  clock: doUploadAthleteList,
  source: $selectedMembership,
  filter: (membershipId) => !!membershipId,
  fn: (membershipId) => ({
    membershipId: membershipId!,
    country: "US",
  }),
  target: createClubFx(uploadFx),
});

// compose(createClubFx, createMembershipFx, uploadFx)();

err(uploadFx.failData, "Upload Athletes Error");

// remove athletes from db after upload to rsportz
sample({
  clock: uploadFx.done,
  target: doClearAthleteList,
});

sample({
  clock: uploadFx.doneData,
  target: rsportzSent,
});

// msg(uploadFx.done, "Athletes uploaded!");

// refresh list on every update
sample({
  clock: [addFx.done, clearFx.done, editFx.done, deleteFx.done],
  target: doGetAthleteList,
});

const $athleteList = createStore<AthleteList>([])
  .on(getListFx.doneData, (_, data) => data)
  .reset(clearFx.done);

const $athleteTable = $athleteList.map((aths) =>
  aths.map((a) => makeListViewItem(a))
);

const doSelectAthlete = createEvent<string | undefined>();

const $selectedAthlete = createStore<Athlete | null>(null);

sample({
  clock: doSelectAthlete,
  filter: (id) => !id,
  fn: () => null,
  target: $selectedAthlete,
});

sample({
  clock: doSelectAthlete,
  source: $athleteList,
  filter: (_, id) => !!id,
  fn: (list, id) => getAthleteFromList(id!, list) || null,
  target: $selectedAthlete,
});

const $canUploadList = combine(
  $athleteList,
  $selectedMembership,
  (athletes, membership) => isValidAthleteList(athletes) && !!membership
);

// global loading for athletes table
const $isLoading = pending({
  effects: [getListFx, addFx, clearFx, editFx, deleteFx, uploadFx],
});

const $membershipOptions = $memberships.map((ms) =>
  ms.map(mapSelectOption("name", "id"))
);

// search

const doSearch = createEvent<string>();

const debouncedDoSearch = debounce({
  source: doSearch,
  timeout: 500,
});

const doFilterError = createEvent<boolean>();

const $isErrorFilter = createStore<boolean>(false).on(
  doFilterError,
  (_, data) => data
);

const $filter = createStore((list: ListViewItem[]) => list).on(
  debouncedDoSearch,
  (_, search) => filterViewItemByEmail(search)
);
//.on(doFilterError, (prev, isFilter) => filterViewItemByErrors(isFilter));

const $filteredTable = combine(
  $athleteTable,
  $filter,
  $isErrorFilter,
  (list, filter, isError) => filter(filterViewItemByErrors(isError)(list))
);

export const useSearch = () => useUnit([doSearch]);

export const useErrorFilter = () => useUnit([doFilterError, $isErrorFilter]);

export const useMembership = () =>
  useUnit([$membershipOptions, doSelectMembership, $selectedMembership]);

export const useFetchDataFromDb = () => {
  useEffect(doGetAthleteList, []);
};
/**
 * Get athletes list from db hook
 * @returns [athleteList, isLoading]
 */
export const useGetList = () => {
  return useUnit([
    $filteredTable,
    $isLoading,
    $filteredTable.map((f) => f.length),
  ]);
};

export const useGetListInfo = () => {
  return useUnit([
    $athleteTable.map((l) => l.length),
    $isLoading,
    $athleteTable.map((l) => l.filter((a) => !a.isValid).length),
    $selectedMembership.map((m) => !!m),
  ]);
};

export const useGetListItem = (index: number) =>
  useStoreMap({
    store: $athleteTable,
    keys: [index],
    fn: (aths, [index]) => aths[index],
  });

/**
 * Add athlete to db hook
 * @returns [doAddAthlete]
 */
export const useAddAthlete = () => {
  return useUnit([doAddAthlete]);
};

/**
 * Edit existed athlete in db hook
 * @returns [doAddAthlete]
 */
export const useEditAthlete = () => {
  return [
    // useStoreMap({
    //   store: $athleteList,
    //   keys: [athleteId],
    //   fn: (aths, [athleteId]) => getAthleteFromList(athleteId, aths),
    // }),
    doEditAthlete,
  ] as const;
};

/**
 * Delete athlete hook
 * @returns [doDeleteAthlete]
 */
export const useDeleteAthlete = () => {
  return useUnit([doDeleteAthlete]);
};

/**
 * Clear athlete list in db hook
 * @returns [doClearAthleteList]
 */
export const useClearAthleteList = () => {
  return useUnit([doClearAthleteList]);
};

const doShowErrors = createEvent();

const debouncedShowErrors = debounce({
  source: doShowErrors,
  timeout: 200,
});

const showErrorFx = createEffect(() => {
  const el = document.getElementById("root");
  el?.setAttribute("class", "showErr");
  setTimeout(() => {
    el?.removeAttribute("class");
  }, 400);
});

sample({
  clock: debouncedShowErrors,
  target: showErrorFx,
});

/**
 * Upload athlete list to rsportz hook
 * @returns [canUploadList, doUploadAthleteList, isUploading]
 */
export const useUploadAthleteList = () => {
  return useUnit([
    $canUploadList,
    doUploadAthleteList,
    doShowErrors,
    uploadFx.pending,
  ]);
};

export const useSelectAthlete = () => useUnit([doSelectAthlete]);
export const useSelectedAthlete = () =>
  useUnit([doSelectAthlete, $selectedAthlete]);

export const useGetRsportzError = (index: number, error: string) =>
  useStoreMap({
    store: $listForError,
    keys: [index],
    fn: (aths, [index]) =>
      `${aths[index].name} (${aths[index].email}): ${error}`,
  });

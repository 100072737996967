import React, { useCallback, useState, type FunctionComponent } from "react";

export type ModalProps<T = any, V = T> = {
  onClose: () => void;
  onSubmit?: (data: T) => void;
  data?: V;
};

export const createUseModal =
  <T = null, V = T, MODALT = undefined>(
    modal: FunctionComponent<ModalProps & MODALT>
  ) =>
  (
    onSubmit?: (data: T) => void
  ): [(props?: MODALT) => JSX.Element | null, (data?: V) => void] => {
    const [show, setShow] = useState<V | undefined>(undefined);
    //
    const closeModal = useCallback(() => setShow(undefined), []);
    const openModal = useCallback((data?: V) => setShow(data), []);
    //

    return [
      typeof show !== "undefined"
        ? (props?: MODALT) =>
            React.createElement(modal, {
              ...(!!props && props),
              data: show,
              onClose: closeModal,
              onSubmit: (data: T) => {
                if (onSubmit) {
                  onSubmit(data);
                }
                closeModal();
              },
            } as ModalProps & MODALT)
        : (_?: any) => null,
      openModal,
    ];
  };

import { API_URL } from "../app/consts";
import type { IHttpService, IRsportzService } from "../app/ports";

import type { Club } from "../domain/club";
import type { RegResponse, RegStatus, RsportzData } from "../domain/rsportz";

export class RsportzService implements IRsportzService {
  constructor(private readonly http: IHttpService) {}
  async getClubInfo(): Promise<Club> {
    const clubData = await this.http.get<Club>(`${API_URL}/api/club`);

    console.log("CLUB INFO", clubData);
    // FOR DEBUG
    //TODO: Remove
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.club = clubData;
    return clubData!;
  }
  async sendAthletes(data: RsportzData): Promise<RegResponse> {
    // FOR DEBUG
    let club: Club | undefined;
    if ("club" in window) {
      club = window.club as Club;
      const debugData = {
        membershipId: data.membershipId,
        athletes: data.athletes,
        clubId: club.clubId,
        userId: club.userId,
        // sportId: club.sportId,
      };
      console.log("RSPORTZ REGISTRATION", debugData);
    }

    const ret = await this.http.post<
      RegResponse,
      RsportzData & { _clubName: string }
    >(`${API_URL}/api/athletes`, { ...data, _clubName: club?.clubName || "" });
    console.log("REGISTATION RESPONSE", ret);
    return ret!;
  }

  async checkStatus(resp: RegResponse): Promise<RegStatus> {
    const clubData = await this.http.get<RegStatus>(
      `${API_URL}/api/status/${resp.request_id}`
    );
    console.log(`"GET STATUS FOR ${resp.request_id} :`, clubData);
    return clubData!;
  }
}

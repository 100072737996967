import ReactDOM from "react-dom/client";
import "virtual:uno.css";
import "./index.css";
import { Main } from "./pages/main/index.tsx";
import { ConfigProvider } from "antd";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://327529015a4544829d6d62708537430a@o4505566902943744.ingest.sentry.io/4505566905565184",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  // <React.StrictMode>
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: "#293886",
      },
    }}
  >
    <Main />
  </ConfigProvider>
  // </React.StrictMode>
);

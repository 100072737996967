import { CSVService } from "../services/csvService";
import { HttpService } from "../services/httpService";
import { RsportzService } from "../services/rsportzService";
import { StorageService } from "../services/storageService";

export const storageService = new StorageService();
export const httpService = new HttpService();

export const rsportzService = new RsportzService(httpService);
export const csvService = new CSVService();
